// for product dropdown depend on destination country
var products;
$(document).on("change", ".des-country-setting", function () {
  var dest_country = $(this).val();
  $("#product-id-hidden-field").val(null);
  $("input[name^='parameter_setting']").prop("checked", false);
  const delivery_type = $('#filter_delivery_type')
  delivery_type.val(delivery_type.find('option:first').val());
  const data = { destination_country: dest_country }
  // console.log(dest_country);
  populateProduct(data);
});

// function ToggleBankRelatedFields(product) {
//   if (product.delivery_type == "Cash Transfer") {
//     $(
//       "#parameter-setting-swift_ifsc, #parameter-setting-bank, #parameter-setting-branch"
//     ).hide();
//   } else {
//     $(
//       "#parameter-setting-swift_ifsc, #parameter-setting-bank, #parameter-setting-branch"
//     ).show();
//   }
// }

function updateParameterSettings() {
  const product = $(".product-setting").val();
  const currency = $(".currency-setting").val();
  if (product && product.trim() !== '') {

    // const foundProduct = products.find(({ id }) => id == product);
    // ToggleBankRelatedFields(foundProduct);
    const category = $(".category-setting").val();
    $("#product-id-hidden-field").val(product);
    $("#category-field").val(category);
    const delivery_type = $('#filter_delivery_type').val();

    $.ajax({
      url: "/parameter_settings/new",
      type: "GET",
      dataType: "json",
      data: { category: category, currency: currency, product: product, delivery_type: delivery_type },
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
      },
      success: function (response) {
        console.log(response)
        const correspondent = response["correspondent"];

        // Uncheck all checkboxes before setting the new values
        $("input[name^='parameter_setting']").prop("checked", false);

        if (response.parameters) {
          response.parameters.forEach(function (param) {
            const customLabel = $(
              "#parameter-setting-" +
              param.field_name +
              ' input[name="parameter_setting[custom_label][' + param.field_name + ']"]'
            );
            customLabel.val(param.custom_label);

            const visibleInSendCheckbox = $(
              "#parameter-setting-" +
              param.field_name +
              ' input[name="parameter_setting[visible_in_send][]"]'
            );
            const mandatoryInSendCheckbox = $(
              "#parameter-setting-" +
              param.field_name +
              ' input[name="parameter_setting[mandatory_in_send][]"]'
            );

            visibleInSendCheckbox.prop("checked", param.visible_in_send);
            mandatoryInSendCheckbox.prop("checked", param.mandatory_in_send);

            if (param.visible_in_send) {
              mandatoryInSendCheckbox.prop("disabled", false);
            } else {
              mandatoryInSendCheckbox
                .prop("disabled", true)
                .prop("checked", false);
            }

            // Similar logic for other checkboxes: visible_in_pay and mandatory_in_pay
            const visibleInPayCheckbox = $(
              "#parameter-setting-" +
              param.field_name +
              ' input[name="parameter_setting[visible_in_pay][]"]'
            );
            const mandatoryInPayCheckbox = $(
              "#parameter-setting-" +
              param.field_name +
              ' input[name="parameter_setting[mandatory_in_pay][]"]'
            );

            visibleInPayCheckbox.prop("checked", param.visible_in_pay);
            mandatoryInPayCheckbox.prop("checked", param.mandatory_in_pay);

            // Add any additional logic or modifications as needed
          });
          $("#is_bank_checkbox").prop(
            "checked",
            response.parameters[0].is_bank_editable
          );
          $("#is_branch_checkbox").prop(
            "checked",
            response.parameters[0].is_branch_editable
          );
        }
      },
    });
  }
}


$(document).on("change", ".product-setting", updateParameterSettings);
$(document).on("change", ".category-setting", updateParameterSettings);
$(document).on("change", "#product-setting", updateParameterSettings);
$(document).on("change", ".currency-setting", function () {
  const destCountry = $(".des-country-setting").val()
  const delivery_type = $('#filter_delivery_type')
  delivery_type.val(delivery_type.find('option:first').val());
  var currency = $(this).val();
  const data = {
    destination_country: destCountry,
    currency: currency
  }
  populateProduct(data);
});
$(document).on("change", ".delivery-type-setting", function () {
  const destCountry = $(".des-country-setting").val()
  var currency = $(".currency-setting").val();
  var deliverType = $(this).val();
  const data = {
    destination_country: destCountry,
    currency: currency,
    delivery_type: deliverType
  }
  populateProduct(data)
});
$(document).ready(function () {
  // Set the value of #category-field to the initial category value
  var category = $(".category-setting").val();
  $("#category-field").val(category);

  // Handle changes in the .visible-in-send-checkbox elements
  $(".visible-in-send-checkbox").on("change", function () {
    var checkbox = $(this);
    var mandatoryCheckbox = checkbox
      .closest("tr")
      .find(".mandatory-in-send-checkbox");

    if (checkbox.is(":checked")) {
      mandatoryCheckbox.prop("disabled", false);
    } else {
      mandatoryCheckbox.prop("disabled", true).prop("checked", false);
    }
  });
  $("#is_bank_checkbox").change(function () {
    var value = $(this).prop("checked");
    $(this).val(value);
  });
  $("#is_branch_checkbox").change(function () {
    var value = $(this).prop("checked");
    $(this).val(value);
  });
});

function populateProduct(data) {
  const deliverType = $(".delivery-type-setting").val()

  $("#delivery-type-hidden-field").val(deliverType)
  $.ajax({
    url: "/parameter_settings/new",
    type: "GET",
    dataType: "json",
    data: data,
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      console.log(response)
      products = response.products
      const currencies = response.currencies
      if (products.length > 0) {
        populateDropdownOptions("product-setting", products)
      }
      if (data.currency === undefined) {
        populateDropdownOptions("currency-setting", currencies)
      }
    }

  })
}

function populateDropdownOptions(clas, options) {
  $("." + clas)
    .empty()
    .html("<option value=''>Please select</option>");
  if (options !== null && options.length > 0) {
    options.forEach(function (option) {
      const optionValue = option.id;
      const optionText = option.name ? option.name : " ";

      $("." + clas).append(
        '<option value="' +
        optionValue +
        '" ' +
        ">" +
        optionText +
        "</option>"
      );
    });
  }
}
